import React from 'react'

import * as S from './_styles'
import * as T from './_types'
import { Button } from '@interco/inter-ui/components/Button'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import IconAndDescription from '../IconAndDescription/_index'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { commonStyles } from '../../_common_styles'

const ApartmentQualitiesCard = ({
  apartmentImage,
  apartmentName,
  apartmentDescription,
  linkForMoreDetails,
  dataLayer,
  className,
}: T.ApartmentQualitiesCardProps) => {
  const [ sendDataLayer ] = useDataLayer()
  const domReady = useDomReady()

  return (
    <S.ComponentWrapper
      className={className}
    >
      <S.ApartmentImage>
        {domReady && apartmentImage.md && (<source srcSet={apartmentImage.md} media='(min-width: 768px)' />)}
        {domReady && apartmentImage.lg && (<source srcSet={apartmentImage.lg} media='(min-width: 1024px)' />)}
        {domReady && apartmentImage.xl && (<source srcSet={apartmentImage.xl} media='(min-width: 1440px)' />)}
        {domReady && apartmentImage.xxl && (<source srcSet={apartmentImage.xxl} media='(min-width: 1920px)' />)}
        <S.ApartmentImageSource
          src={apartmentImage.sm}
          alt={apartmentImage.alt}
        />
      </S.ApartmentImage>

      <S.Texts>
        <S.Title>{apartmentName}</S.Title>
        <S.Description>
          { apartmentDescription && apartmentDescription.map((description: T.DescriptionItem) => (
            <IconAndDescription
              key={description.title}
              icon={
                <IconsSwitch
                  icon={description.icon}
                  customPath={description.customPath}
                  color={commonStyles.sandColor}
                  lib='interco'
                  width='20'
                  height='20'
                  className='apartments_card__description__item__icon__source'
                />
              }
              description={description.title}
              className='apartments_card__description__item'
              iconClassName='apartments_card__description__item__icon'
            />
          ))}
        </S.Description>
        <Button
          as='a'
          target='_blank'
          href={linkForMoreDetails}
          fullWidth
          variant='primary'
          onClick={() => sendDataLayer({
            section: dataLayer.section,
            section_name: dataLayer.section_name,
            element_name: 'Saiba mais',
            element_action: 'click button',
            redirect_url: linkForMoreDetails,
          })}
        >
          Agende a sua visita
        </Button>
      </S.Texts>
    </S.ComponentWrapper>
  )
}

export default ApartmentQualitiesCard
