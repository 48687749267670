import styled from 'styled-components'
import { commonStyles } from '../../_common_styles'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled(commonStyles.sectionWrapper)`
`

export const Title = styled(commonStyles.sectionSecondaryTitle)`
  margin-top: 24px;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 34px;

    margin-top: 0;
  }

  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 53px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const CentralImage = styled.img`
  width: 100%;
  height: auto;
  margin: auto 0;
`

export const QualitiesList = styled.ul`
  margin: 24px 0;

  @media ${device.tablet} {
    margin: 40px 0;
  }
`

export const ListItem = styled.li`
  list-style: none;
  margin: 12px 0%;

  @media ${device.tablet} {
    margin: 16px 0;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
