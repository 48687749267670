import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import * as newColors from 'src/styles/newColors'

import { commonStyles } from '../../_common_styles'

export const SectionWrapper = styled(commonStyles.sectionWrapper)`

  @media ${device.desktopXXL} {
    padding: 185px 0;
  }
`

export const Title = styled.p`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 44px;
  color: white;

  margin: 0;

  @media ${device.tablet} {
    color: ${newColors.primary[500]};
    font-size: 48px;
    line-height: 53px;
  }

  @media ${device.desktopLG} {
    font-size: 56px;
    line-height: 62px;
  }

  @media ${device.desktopXXL} {
    font-size: 72px;
    line-height: 79.2px;
    letter-spacing: -2%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin-top: 48px;
  padding: 0;

  @media ${device.tablet} {
    margin: 0;
  }
`

export const HeroImage = styled.picture`
  display: flex;
  width: 100%;
`

export const HeroImageSource = styled.img`
  width: 100%;
  height: auto;
`

export const InterResidenceLogo = styled.img`
  width: 157px;
  margin-bottom: 16px;

  @media ${device.tablet} {
    width: 213px;
    margin-bottom: 46px;
  }

  @media ${device.desktopLG} {
    width: 247px;
  }

  @media ${device.desktopXXL} {
    width: 286px;
  }

`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
