import React from 'react'

import * as S from './_styles'
import { Button } from '@interco/inter-ui/components/Button'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import listContent from './listContent.json'
import IconAndDescription from '../../components/IconAndDescription/_index'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { commonStyles } from '../../_common_styles'

type ListContentItemType = {
  icon: {
    name: string;
    customPath: string;
  };
  description: string;
}

const TodaInovacaoEPraticidade = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.SectionWrapper id='toda-inovacao-e-praticidade-do-inter'>
      <div className='container'>
        <S.Row className='row'>
          <S.CentralImage
            className='col-12 col-md-6 col-lg-5'
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/residence-dobra2-1440/image.webp'
            alt='pessoa segurando celular com a home do super app inter aberta'
          />
          <S.Texts className='col-12 col-md-6 col-xl-5'>
            <S.Title>
              Toda a inovação e praticidade do Inter, agora também na sua casa.
            </S.Title>
            <S.QualitiesList>
              {listContent.map((item: ListContentItemType) => (
                <S.ListItem key={item.icon.name}>
                  <IconAndDescription
                    icon={
                      <IconsSwitch
                        icon={item.icon.name}
                        customPath={item.icon.customPath}
                        color={commonStyles.sandColor}
                        width='20'
                        height='20'
                        lib='interco'
                      />
                    }
                    description={item.description}
                  />
                </S.ListItem>
              ))}
            </S.QualitiesList>
            <Button
              as='a'
              href='#muitas-possibilidades-pra-voce-escolher-um-apartamento'
              fullWidth
              onClick={() => sendDataLayer({
                section: 'dobra_02',
                section_name: 'Toda a inovação e praticidade do Inter, agora também na sua casa.',
                element_action: 'click button',
                element_name: 'Saiba mais',
                redirect_url: '#muitas-possibilidades-pra-voce-escolher-um-apartamento',
              })}
            >
              Saiba mais
            </Button>
          </S.Texts>
        </S.Row>
      </div>
    </S.SectionWrapper>
  )
}

export default TodaInovacaoEPraticidade
