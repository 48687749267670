import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { commonStyles } from '../../_common_styles'

export const ComponentWrapper = styled.article`
  display: flex;
  align-items: center;
`

export const Icon = styled.div`
  height: 100%;
  width: 24px;

  margin-right: 24px;
  
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    width: 28px;
  }

  @media ${device.desktopLG} {
    width: 32px;
  }
`

export const Description = styled.div`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${commonStyles.sandColor};

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }

`
